.suggestions{
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0;
    height: auto;
    width: 100%;
    border-radius: 0 0 3px 3px;
    transition: none;
    font-size: 1rem;
    z-index: 100;
}
.form-control--focus {
    border-color: #003bcf;
    box-shadow: 0 0 0 0.2rem rgba(0, 59, 207, 0.4);
}
.suggestion{
    cursor: default;
    transition: none;
    font-size: 1rem;
    padding: 5px 10px;
    color: #555;
    margin-bottom: 5px;
}
.suggestion:hover{ 
    transition: none;
    font-size: 1rem;
    background: rgba(38, 132, 255, 0.08);
}
