.info-card {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgb(224, 224, 224) 0px 0px 15px;
}

.card-img-container {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.card-img {
    border-radius: 0;
    height: 220px;
    width: auto;
}

.card-content-container {
    display:flex;
    align-items:center;
}