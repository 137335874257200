.search-box .search-location{
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 535px;
    box-shadow: 0 0 14px #cfcfcf;
    background: #fff;
    padding: 0;
    border-radius: 3px;
    display: flex;
    margin-right: 0;
}
.search-col h6{
    margin: -2px 0;
    padding: 0;
}
.search-col .input-group-prepend{
    margin: 0;
}
.search-col .form-control{
    padding: 0;
    height: 40px;
    min-height: 40px;
}
.search-col{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    padding: 8px 0 5px 1rem;
    align-items: stretch;
}
.search-box .search-btn,
.search-box .search-btn:focus{
    width: auto;
	background-color: #003BCF;
	border-color: #003BCF;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
}
.search-box .search-btn span{
    display: block;

}

.doctor-search-container {
    max-width: unset;
}

.section-search{
    background-color: #F9FAFF;
}
.section-search .text-primary{
    color: #003BCF;
}

.banner-wrapper .banner-header h1{
    font-size: 3rem;
    font-weight: bold;
}
.banner-info{
    font-weight: bold;
    font-size: 2rem;
}

@media only screen and (max-width: 991px){
    .doctor-mr {
        flex-wrap: wrap-reverse!important;
        justify-content: flex-end!important;
    }
    .doctor-top {
        left:0!important;
    }
    .doctor-join-container {
        width: 50%;
    }
    .doctor-join-container h2{
        justify-content: flex-end !important;
    }
}

@media only screen and (max-width: 900px){
    .doctor-join-container {
        width: 65%;
    }
}

@media only screen and (max-width: 767px){
    .doctor-join-container {
        width: 65%;
         margin-top: 50px;
    }
}

@media only screen and (max-width: 702px){
    .doctor-join-container {
        width: 75%;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 575px){
    .doctor-join-container {
        width: 100%;
        margin-top: 85px;
    }
}

@media only screen and (max-width: 440px){
    .doctor-join-container {
        margin-top: 45px;
    }
    .doctor-join-container h2, button {
        font-size: 20px!important;
    }
    .doctor-join-container img{
        width: 74px;
        height: 84px;
    }
}

@media only screen and (max-width: 767.98px){
    .banner-header{
        max-width: 535px;
        margin: 0 auto;
    }
    .banner-wrapper .banner-header h1{
        font-size: 2.5rem; 
    }
    .banner-info{ 
        font-size: 2rem;
    }
    .banner-info-wrapper .banner-info{
        width: 100%;
        text-align: center;
    }
    .search-box .search-btn{
        width: 100% !important;
    }
}

.banner-wrapper .banner-header p {
    color: #1c5cfd;
}
.banner-bottom .banner-bottom-content{
    background: #003BCF;
    color: #FFF;
    padding: 1rem;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
}
.banner-info-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.banner-container{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}


.banner-header-img-wrapper{   
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-basis: 100px;
    flex: 1 0 200px;
    margin-left: 1rem;

}
.banner-wrapper{
    max-width: 1200px;
}

.doctor-top {
    left: -70px;
    bottom: 0;
}