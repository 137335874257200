
.tabbed-login{
    border-bottom: 1px solid rgba(216,216,216,0.5); 
}
.tabbed-login .nav-link.active{
    border-bottom: 5px solid #003bcf;
    color: #003bcf  !important;;
}

.tabbed-login .nav-link{
    color: #333 !important;
}