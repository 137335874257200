.text-primary {
  color: #2957ee !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}
.btn.btn-primary,
.breadcrumb-bar,
.time-slot li .timing.selected,
.time-slot .timing.selected {
  background: linear-gradient(
    109.15deg,
    #2957ee 12.66%,
    #426af4 74.64%
  ) !important;
  border: 2px solid transparent !important;
  color: #fff;
}
.flex1 {
  flex: 1;
}
.w100 {
  width: 100%;
}

.btn.btn-outlint-primary {
  color: #2957ee !important;
  border-color: #2957ee !important;
}
.App {
  background-color: #f9faff;
}
.card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(146, 151, 167, 0.1);
  border-radius: 5px;
}
.user-tabs .nav-tabs > li > a {
  border-bottom: 4px solid #f0f0f0;
}
.listing-day {
  justify-content: center;
}
.time-items > span {
  font-size: 12px;
}
.text-sm {
  font-size: 12px !important;
}
.right-app {
  float: right;
}

.time-slot .timing {
  position: relative;
  padding-right: 20px;
}
.time-slot .timing.selected {
  border: 1px solid #2957ee !important;
}
.booking-schedule {
  box-shadow: none;
  margin: auto;
}
.shadow1 {
  box-shadow: 0px 4px 8px rgba(146, 151, 167, 0.1);
  border-radius: 5px;
  background-color: #fff;
}
.book-app-container {
  min-width: 330px;
  flex: 1;
  max-width: 330px;
  flex-grow: 1;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .book-app-container {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 1 100%;
  }
}
.findnow-btn {
  padding: 3px 10px;
  border-radius: 13px;
  min-width: 130px;
  font-size: 16px;
}
.card-text,
.text-secondary,
.custom_check,
.custom_radio,
.doc-location,
.clini-infos ul li {
  color: #9297a7 !important;
}
.flexWrap {
  flex-wrap: wrap;
}
.home p {
  font-weight: 500;
  color: #9297a7;
}
h2 {
  font-size: 36px;
  font-weight: 500;
}
.shadow-0 {
  box-shadow: none !important;
}

.round-indicator li {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
}
.b-400 {
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .nav-tabs.nav-justified {
    border: none !important;
  }
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border: none !important;
    border-bottom: 4px solid #426af4 !important;
    background-color: initial;
  }
  .home-tile-section .card-title, .user-actions-ha-p .card-title {
    text-align: center;
  }
  .home-tile-section .card-text, .user-actions-ha-p .card-text{
    text-align: center;
  }
}
.user-tabs .nav-tabs > li > a {
  color: #9297a7;
}
.booking-card {
  font-size: 14px;
}
.nav-link {
  font-size: 15px;
}
.text-capialize {
  text-transform: capitalize;
}
.text-dark {
  color: #242425 !important;
}
.f-c-1 {
  font-size: 14px;
}

.download-app {
  padding: 5rem;
  margin: auto;
}
.right-filter{
  position: relative;
  overflow: visible;
  box-sizing:border-box;
  width: 300px;
  min-height: 1px;
  padding-right: 20px;
}
/**  RESPONSIVE **/
.mm12{
  margin-left: 12px !important;
}
.bar-icon{
  display: none;
}
.header-nav {
  height: auto !important;
}
@media only screen and (max-width: 575px) {
  .filter-container{
    flex: 1 1 100%
  }
  .right-container{
    margin: 5px;
  }
  .doctor-widget ul{
    text-align: center;
  }
  .container-fluid .row{
    margin-left: 0;
    margin-right: 0;
  }
  .clinic-booking-container .btn{
    margin: 10px auto;
  }
   .right-filter{
     width: auto;
     margin: 5px;
     padding-right: 0 !important;
   }
  .header-nav {
    height: auto !important;
  }
  .bar-icon{
    display: block;
  }
  .search-box{
    margin: 15px 0;
  }
  .search-location{
    padding: 10px !important;
  }
  .theiaStickySidebars{
    width: 100%;
  }
  .hm,
  .banner-header-img-wrapper {
    display: none !important;
  }
  .d-flex {
    flex-wrap: wrap;
  }
  .download-app {
    padding: 0;
    margin: 0;
  }
  .footer li,
  .footer p,
  .footer,
  .footer-address,
  .footer ul,
  .m-center {
    text-align: center;
    justify-content: center;
  }
  .header-navbar-rht {
    margin: 1rem;
  }
  .container-fluid,.booking-schedule{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .book-app-container{
    min-width: 100px;
  }
  .search-box.container-fluid,.search-box{
    width: 100%;
  } 
  .search-col{
    padding: 10px;
    font-size: 11px;
  }
  .search-box .container{
    padding: 0 5px; 
  }
  .mm12{
    margin-left: 0 !important;
  }
  .search-box .search-location{
    display: flex;
    flex-direction: column;
  }
  .search-box .autosuggest {
    margin-left: -5px;
    background-color: transparent;
    z-index: 10000;
  }
  .search-box .btn{
    width: 100%;
    margin: 5px;
  }
  h1{
    font-size: 2rem !important;
  }
  h2{
    font-size: 1.8rem;
  }
  h3{
    font-size: 1.4rem;
  }
  .custom_radio {
    width: 96%;
  }
  .section .row, .schedule-header .row,  .footer .row, .type .row{
    margin-left: 0;
    margin-right: 0;
  }
  .type .w100{
    display: flex;
  }
  .section.section-search{
    padding: 0;
  } 
  .home-review.p-5,.home-review .p-5 {
    padding: 5rem 0 !important;
  }
  .home-review .m-center .btn{
    margin: 5px  0 !important;
  }
  .controls-top {
    width: 94%;
    display: none;
  }
  .home-tile-section .card-img{
    /*max-height: 100px !important;
    max-width: 100px;*/
    width:100%;
    height:auto;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 0;
  }
  .home-tile-section>div>.row{
    margin: 0 auto;
  }
  .booking-schedule.schedule-widget > div{
    width: 100%;
  }
  .day-slot .header{
    flex-wrap: wrap;
    display: flex;
  }
  .day-slot .header li,.arrowRight{
    flex: 1;
  }
}

.custom-search-container{ 
  box-shadow: 0px 4px 8px rgba(146, 151, 167, 0.5);
  z-index:1000;
  top:50px; 
  max-height:200px;
  overflow-y:auto;
  background-color: rgb(250, 250, 250);
}

.custom-search-container p {
  cursor: pointer;
  padding: 0.8rem 1rem;
}

.custom-search-container p:hover {
  background-color: #2957ee;
  color: #eee;
  transition-duration: 0.3s;
}

.cursor-pointer{
  cursor:pointer;
}

.pac-container{
  z-index: 10000;
}

.status-pills{
  background-color: rgb(233, 233, 233);
  border-radius: 50px;
  padding: 10px;
  white-space: nowrap;
}

.rec.rec-arrow {
  width: 30px;
  height: 30px;
  min-width: unset;
  min-height: unset;
  font-size: 12px;
  line-height: unset;
  font-weight: normal;
}

.rec.rec-arrow {
  background-color: #fff;
}

.rec.rec-arrow:hover, .rec.rec-arrow:focus:enabled, .rec.rec-arrow:hover:enabled {
  background-color: rgb(62, 103, 243);
  box-shadow: 0 0 2px 0px rgb(62, 103, 243);
}

.rec.rec-arrow:disabled, .rec.rec-arrow:disabled:hover {
  background-color: rgba(169, 189, 255, 0.1);
}
.react-datepicker-popper {
  z-index: 10000;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.f-34 {
  font-size: 34px !important;
}

.action-card { 
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px #e0e0e0;
}

.policy-menu li a:hover, .policy-menu li a:focus {
  color: #fff;
}