/** common css classes**/
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn.btn-primary {
  background: #2957EE linear-gradient(
    109.15deg,
    #2957ee 12.66%,
    #426af4 74.64%
  ) !important;
  border-color: #2957EE;
  color: #fff;
}

.book-btn1:hover,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.about-content a:hover,
.about-content a:focus {
  background-color: #0031ab;
  border-color: #0031ab;
  text-decoration: none;
  color: #fff;
}
.text-primary {
  color: #003bcf !important;
}
a {
  color: #003bcf;
}
.grid-blog .post-author a:hover,
a:hover {
  color: #1a85f9;
}
.form-control:focus {
  border-color: #003bcf;
  box-shadow: 0 0 0 0.2rem rgba(0, 59, 207, 0.2);
}
.btn.view-btn:hover,
.btn.view-btn:focus {
  color: #fff;
}
.btn.book-btn:hover,
.btn.book-btn:focus {
  background-color: #0031ab;
  border-color: #0031ab;
  color: #fff;
}
.breadcrumb-bar {
  color: #fff;
  background: #003bcf;
}
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
button:focus {
  outline: none;
}

/** Form field password eye */
.password-eye {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  padding: 0 25px 0 5px;
}
input::-ms-reveal{
  display: none;
}
.form-control{
  height: 49px;
}
.password.form-control{
  background-image: none !important;
}

.fileUpload{
  margin: 0;
  width: 100%;
  border: 1px dashed #aaa;
  text-align: center;
  cursor: pointer;
  padding: .65rem 0;
  border-radius:  4px;
  flex-grow: 1;
}
.fileUpload:hover,
.fileUpload:active{
  background: #eee;
}
._40px{
  height: 40px;
}
.login-header h3 .btn-link{ 
  color: #003BCF;
  float: right;
  font-size: 15px;
  margin-top: 2px;
}
.login-header h3.text-danger .btn-link{ 
  color: #dd4f39;
}

.clinic-booking-container .btn:hover,
.clinic-booking-container .btn:focus,
.clinic-booking-container .btn:active,
.clinic-booking-container .btn{
  font-size: 15px;
  border-radius: 4px;
  border-width: 2px
}
.nav-link{
  cursor: pointer;
}
.booking-doc-info .booking-doc-img{
  width: auto;
  text-align: center;
}
.booking-doc-info .booking-doc-img img{
  display:block;
  width: 120px;
  height: 120px;
}
.timing{
  cursor: pointer;
}
.search-box .search-location{
  max-width: 700px;
}
.autosuggest {
  padding: 0 !important;
  transition:none;
  display: flex;
  height: 30px;
  border: none !important;
  transition: none !important;
}
.autosuggest .auto__control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0;
}

.autosuggest .auto__menu{
  margin-top: 0;
  border-radius: 0
}
.search-box{
  max-width: 700px;
  width: 90%;
}
 
html {
  height: 100%;
}

body{
  font-family: Poppins, 'Lato', sans-serif;
  color: #888;
  margin: 0;
}

.error-page{
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
}
.light-bg{
  background: #f9f9f9;
}
.form-control{
  z-index: 2;
}
.form-control:focus{
  z-index: 10;
}
*{
  transition: 0;
}
.time-slot li{
  width: 12.5% !important
}
.day-slot .header{
  display: flex;
  justify-content: space-around;
}
.day-slot .header li{
  width: 100%;
  align-content: stretch;
}
.day-slot li {
  padding: 5px;
}

.day-slot li span{
  cursor: pointer;
  transition: none !important;
} 
.day-slot li span.slot-date,.timing{
  cursor: pointer;
  transition: none !important;
  background: transparent;
}
.activeWeekDay,.time-slot li .timing.selected{ 
  cursor: pointer;
  transition: none !important;
  border-radius: 3px;
}

.arrowLeft,.arrowRight{
  width : 50px !important;
  line-height : 40px;
  font-size: 1.2rem;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.arrowLeft:hover,.arrowRight:hover{
  color: #333;
  cursor: pointer;
}
.arrowLeft.disabled:hover,.arrowRight.disabled:hover,
.arrowLeft.disabled,.arrowRight.disabled{
  color: #ccc !important;
}
.phone-number{
  display: flex;
}
.phone-number>div:nth-child(1){
  margin-right: 5px;
  width: 130px;
}
.phone-number>div:nth-child(2){
  flex-grow: 1;
}

/* Doctor appointments
 *
 */
.todays-appointment-tab .patient-name,
.todays-appointment-tab .appointment-date,
.todays-appointment-tab .view-profile, 
.todays-appointment-tab .medical-history, 
.todays-appointment-tab .fill-presciption,
.past-appointment-tab .patient-name,
.past-appointment-tab .appointment-date,
.past-appointment-tab .view-profile, 
.past-appointment-tab .medical-history, 
.past-appointment-tab .fill-presciption,
.future-appointment-tab .patient-name,
.future-appointment-tab .appointment-date,
.future-appointment-tab .view-profile, 
.future-appointment-tab .medical-history, 
.future-appointment-tab .fill-presciption
{
  display: block;
}

.todays-appointment-tab .patient-name, 
.past-appointment-tab .patient-name, 
.future-appointment-tab .patient-name 
{
  font-size: 1.2em;
  color: #003BCF;
}
 
.todays-appointment-tab .pagination ,
.past-appointment-tab .pagination ,
.future-appointment-tab .pagination
{
  margin-left: 2%;
} 
  
.todays-appointment-tab table, 
.past-appointment-tab table,
.future-appointment-tab table 
{
  height: 100%;
}

.todays-appointment-tab td.action, 
.past-appointment-tab td.action, 
.future-appointment-tab td.action 
{
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.todays-appointment-tab img, 
.past-appointment-tab img,
.future-appointment-tab img 
{
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.doctor-fill-prescription {
  font-family: 'Poppins';
}

.doctor-fill-prescription .add-item button {
  width: 154px;
  height: 40px;
  background: #F8FAFF;
  border: 1px solid #2957EE;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-fill-prescription .delete-item-container button {
  width: 40px;
  height: 40px;
  background: #FFF5F5;
  border: 1px solid #FD5E61;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-fill-prescription .delete-item-container {
  display: flex;
  justify-content: center;
}

.doctor-fill-prescription .delete-item-container button i {
  color: #FD5E61;
}

.doctor-fill-prescription .add-item {
  display: flex;
  justify-content: flex-end;
}

.doctor-fill-prescription .add-item button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2957EE;
}

.doctor-fill-prescription .add-item i {
  margin-right: 0.4rem;
}

.doctor-fill-prescription .row{
  margin: 15px 5px;
}

.doctor-fill-prescription .row .medicine-header{
  margin: 25px 0px;
}

.doctor-fill-prescription .row .lab-test-header{
  margin: 25px 0px;
}

.doctor-fill-prescription .row .general-instructions-header{
  margin: 25px 0px;
}

.doctor-fill-prescription input {
  background: #FFFFFF;
  border: 1px solid #CACCD0;
  box-sizing: border-box;
  padding: 0 8px;
  border-radius: 5px;
  height: 42px;
}

.doctor-fill-prescription textarea {
  width: 100%;
  height: 126px;
  background: #FFFFFF;
  border: 1px solid #CACCD0;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-fill-prescription .doctor-details .address-container .name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #242425;
  display: block;
}

.doctor-fill-prescription .doctor-details .address-container .address {
  width: 245px;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9297A7;
}

.doctor-fill-prescription .doctor-details .address-container .phone {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9297A7;
}

.doctor-fill-prescription .todays-date-container .todays-date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #242425;
  align-content: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.doctor-fill-prescription .medicine-header {
  margin: 2rem 0;
}

.doctor-fill-prescription .medicine-header .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-fill-prescription .lab-test-header {
  margin: 2rem 0;
  margin-top: 3rem;
}

.doctor-fill-prescription .lab-test-header .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-fill-prescription .medicine-items .name ,
.doctor-fill-prescription .medicine-items .comment 
{
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #242425;
}

.doctor-fill-prescription .delete-item-container
{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
}

.doctor-fill-prescription .general-instructions-container
{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}
 
.doctor-fill-prescription .dispensed-input,
.doctor-fill-prescription .not-dispensed-input
{
  width: 17px;
  height: 17px;
  background: #2957EE;
}

.doctor-fill-prescription .dispensed-container 
{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-fill-prescription .validationMessage 
{
  color: red;
}
  
.normalDay{
  border-bottom: 1px solid #F0F0F0;
  border-radius: 0;
}
.activeWeekDay{
  border-radius: 0;
  border-bottom: 2px solid #426AF4;
  color: #426AF4;
}

/* medical history */

.doctor-medical-history {
  font-family: 'Poppins';
}

.doctor-medical-history .add-item button {
  width: 154px;
  height: 40px;
  background: #F8FAFF;
  border: 1px solid #2957EE;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-medical-history .delete-item-container button {
  width: 40px;
  height: 40px;
  background: #FFF5F5;
  border: 1px solid #FD5E61;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-medical-history .delete-item-container {
  display: flex;
  justify-content: center;
}

.doctor-medical-history .delete-item-container button i {
  color: #FD5E61;
}

.doctor-medical-history .add-item {
  display: flex;
  justify-content: flex-end;
}

.doctor-medical-history .add-item button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2957EE;
}

.doctor-medical-history .add-item i {
  margin-right: 0.4rem;
}

.doctor-medical-history input {
  background: #FFFFFF;
  border: 1px solid #CACCD0;
  box-sizing: border-box;
  padding: 0 8px;
  border-radius: 5px;
  width: 100%;
  height: 37px;
}

.doctor-medical-history textarea {
  width: 100%;
  height: 126px;
  background: #FFFFFF;
  border: 1px solid #CACCD0;
  box-sizing: border-box;
  border-radius: 5px;
}

.doctor-medical-history .doctor-details .address-container .name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #242425;
  display: block;
}

.doctor-medical-history .doctor-details .address-container .address {
  width: 245px;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9297A7;
}

.doctor-medical-history .doctor-details .address-container .phone {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9297A7;
}

.doctor-medical-history .todays-date-container .todays-date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #242425;
  align-content: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.doctor-medical-history .medicine-header {
  margin: 2rem 0;
}

.doctor-medical-history .medicine-header .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-medical-history .lab-test-header {
  margin: 2rem 0;
  margin-top: 3rem;
}

.doctor-medical-history .lab-test-header .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-medical-history .medicine-items .name ,
.doctor-medical-history .medicine-items .comment 
{
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #242425;
}

.doctor-medical-history .delete-item-container
{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
}

.doctor-medical-history .general-instructions-container
{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-medical-history .dispensed-container 
{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-medical-history .validationMessage 
{
  color: red;
}

.doctor-medical-history .social-header ,
.doctor-medical-history .family-header ,
.doctor-medical-history .allergic-header {
  margin : 20px 0px;   
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242425;
}

.doctor-medical-history .title {
  margin : 20px 0px;   
  padding: 0;
}

.doctor-medical-history {
  margin:20px 0px;
}

.doctor-medical-history .detail,
.doctor-medical-history .doctor-container .doctor,
.doctor-medical-history .date-container .date

{
  color: #242425;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #242425;
}

.doctor-medical-history .add-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.family-history-items,
.allergic-history-items, 
.social-history-items 
{
  margin-top: 15px;
}

.doctor-banking-details input{
  width: 100%;
}

.doctor-banking-details .terms-and-cond {
  width: 22px !important;
}

.validationMessage {
  color :red
}