.info-section {
    background-color: #e8f6ff;
    .info-section-header {
        h2 {
            font-size: 40px;
            .header-span {
                color: #004a87;
            }
        }
        p {
            color: #778097;
            font-size: 14px;
        }
    }
    .info-section-content {
        h4 {
            color: #181e3f;
        }
        p {
            color: #778097;
        }

        .icon-container {
            border-radius: 50%;
            border: 2px dashed #dee7ee;
            .image-container {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-color: #fff;
                img{ 
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
}